import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import Paper from "@material-ui/core/Paper";
import { Spinner, Tabs, Tab, Button, Container } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const dataReducer = (mergedData, action) => {
  switch (action.type) {
    case "initial":
      return action.payload;
    case "keywords":
      return action.payload;
    default:
      return mergedData;
  }
};

function Reg13Check() {
  const [reg13Data, setReg13Data] = useState([]);
  const [gpsnetData, setGpsnetData] = useState(0);
  const [gpsnetDataLen, setGpsnetDataLen] = useState(0);
  const [nswSite, setNswSite] = useState(0);
  const [loading, setLoading] = useState(true);
  const localCorsData = JSON.parse(localStorage.getItem("corsState"));
  const [d_tab, setD_tab] = useState("npi");
  const [npiSite, setNpiSite] = useState([]);
  const [decommissionedSite, setDecommissionedSite] = useState([]);
  const [unavailableSite, setUnavailableSite] = useState([]);

  const tableStyle = {
    width: "80%",
    minWidth: "650px",
    margin: "0 auto",
  };

  const getNpiSite = (data) => {
    let result = data.filter(
      (item) =>
        item.site_host.toLowerCase() === "ga" &&
        item.site_status.toLowerCase() !== "decomissioned"
    );
    setNpiSite(result);
    // setNpiDataLen(result.length);
  };

  const getNswSite = (data) => {
    let result = data.filter(
      (item) =>
        item.site_host.toLowerCase() === "corsnet-nsw" &&
        item.site_status.toLowerCase() !== "decomissioned"
    );
    setNswSite(result);
  };

  const getGpsnetSite = (data) => {
    let result = data.filter(
      (item) =>
        !item.site_host.toLowerCase().includes("ga") &&
        !item.site_host.toLowerCase().includes("corsnet-nsw") &&
        item.site_status.toLowerCase() !== "decomissioned"
    );
    setGpsnetData(result);
    setGpsnetDataLen(result.length);
  };

  const getDecommissionedSite = (data) => {
    let result = data.filter(
      (item) => item.site_status.toLowerCase() === "decomissioned"
    );
    setDecommissionedSite(result);
  };

  const assignData = (mergedData) => {
    getNpiSite(mergedData);
    getGpsnetSite(mergedData);
    getNswSite(mergedData);
    getDecommissionedSite(mergedData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://s3api.vicpos.com.au/get_reg13_status_v2"
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };

    const getReg13_exp_date = async () => {
      try {
        const response = await axios.get(
          "https://cms.vicpos.com.au/cors?_limit=-1"
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    };

    const mergeData = (data1, data2) => {
      // merge data1 and data2 based on the site name and push the site code to unavailableSite it cound not find a match in data2
      // get all data2 site name and push it to data2list
      let data2List = data2.map((item) => item.code);
      // get all data1 site name, reg13_exp_date and push it to data1list
      let data1List = data1.map((item) => item.site);
      console.log(data1List);
      console.log(data1);
      // if data1List code is not data2List, push it to unMatchSite list like {site: code}
      let unMatchSite = data2List.filter((item) => !data1List.includes(item));
      // formate the unMatchSite to the same format as data2 {"site": item, "file_name": "","modified_date": "","site_host": "","site_status": ""},
      let jsonUnMatchSite = unMatchSite.map((item) => {
        return {
          site: item,
          file_name: "",
          modified_date: "2024-01-01 00:00:00",
          site_host: "",
          site_status: "",
          reg13_exp_date: "2024-01-01 00:00:00",
        };
      });
      setUnavailableSite(jsonUnMatchSite);
      // merge data1 and data2
      let mergedData = data1.map((obj1) => {
        const obj2 = data2.find((obj2) => obj2.code === obj1.site);
        return obj1 ? { ...obj2, ...obj1 } : obj2;
      });

      // let mergedData = data1.map((obj1) => {
      //   const obj2 = data2.find((obj2) => obj2.site === obj1.site);
      //   return obj1 ? { ...obj2, ...obj1 } : obj2;
      // });
      console.log(mergedData);
      assignData(mergedData);
      setReg13Data(mergedData);
    };

    const loadData = async () => {
      const [data1, data2] = await Promise.all([
        fetchData(),
        getReg13_exp_date(),
      ]);
      const mergedData = mergeData(data1, data2);
      setLoading(false);
    };

    loadData();
  }, []);

  const searchHandler = (keyword) => {
    const newList = reg13Data.filter((fs) => {
      return Object.values(fs)
        .join(" ")
        .toLowerCase()
        .includes(keyword.toLowerCase());
    });
    assignData(newList);
  };

  const columns = [
    {
      dataField: "site",
      text: "Site",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      formatter: (cell, row) => (
        <Button
          disabled={row.modified_date === "not available"}
          variant={
            row.modified_date === "not available" ? "warning" : "primary"
          }
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `https://ga-gnss-products-v1.s3.amazonaws.com/public/reg13/${row.file_name}`,
              "_blank"
            );
          }}>
          {row.site}
        </Button>
      ),
    },
    {
      dataField: "modified_date",
      text: "Last Modified",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      // calculate the last_modified_date compare to today and display the result as how many days ago.
      formatter: (cell, row) => {
        const today = new Date();
        const modifiedDate = new Date(cell);
        const diffTime = Math.abs(today - modifiedDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays + " days ago";
      },
    },
    {
      dataField: "modified_date",
      text: "Modified Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      sortFunc: (a, b, order, dataField) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      },
    },
    {
      dataField: "reg13_exp_date",
      text: "Expiry Date",
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc") return <IoIosArrowUp />;
        if (order === "desc") return <IoIosArrowDown />;
        return null;
      },
      sortFunc: (a, b, order, dataField) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      },
      formatter: (cell, row) => {
        const expDate = new Date(cell);
        const currentDate = new Date();
        const diffTime = Math.abs(expDate - currentDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const style = () => {
          if (expDate <= currentDate) {
            return { backgroundColor: "red" };
          } else if (diffDays < 365) {
            return { backgroundColor: "orange" };
          } else {
            return {};
          }
        };
        return <span style={style()}>{cell}</span>;
      },
    },
    // Add more columns as needed
  ];

  return (
    <Container>
      <h1>Reg13 Checker</h1>
      {/* get a keyword search box below */}
      <TextField
        aria-describedby="basic-addon1"
        label="Keyword"
        id="keyword"
        onChange={(e) => searchHandler(e.target.value)}
        margin="5px"
        variant="outlined"
        style={{ width: "100%", marginBottom: "5px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={(e) => searchHandler(e.target.value)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {loading ? (
        <div className="spinner">
          <Spinner animation="border" variant="primary" /> Loading...
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey={d_tab}>
            {/* GET A COUNT OF THE npiDataLen next to the title in a chips */}
            <Tab eventKey="npi" title={`NPI (${npiSite.length})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={npiSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
            <Tab eventKey="gpsnet" title={`GPSnet (${gpsnetData.length})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={gpsnetData}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
            <Tab
              eventKey="CORSnet-NSW"
              title={`CORSnet-NSW (${nswSite.length})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={nswSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
            <Tab
              eventKey="Decommissioned"
              title={`Decommissioned (${decommissionedSite.length})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={decommissionedSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
            <Tab
              eventKey="Unavailable"
              title={`Unavailable (${unavailableSite.length})`}>
              <div>&nbsp;</div>
              <BootstrapTable
                keyField="id"
                data={unavailableSite}
                columns={columns}
                striped
                hover
                responsive
                style={{ width: "80%", margin: "10 auto" }}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </Container>
  );
}

export default Reg13Check;
