import React, {
  useReducer,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import {
  Map,
  Marker,
  TileLayer,
  Popup,
  Tooltip,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import { Form, Spinner, Badge } from "react-bootstrap";
import { CorsAzureMelContext } from "../contexts/CorsAzureMelContext";
import { ApiContext } from "../contexts/ApiContext";
import { Link } from "react-router-dom";
import Axios from "axios";
import {
  ChartBarIcon,
  GlobeAltIcon,
  LightningBoltIcon,
  ExternalLinkIcon,
  PencilAltIcon,
  ChipIcon,
  StatusOnlineIcon,
  ClockIcon,
  ClipboardIcon,
} from "@heroicons/react/solid";

import { TextField, Button, Chip, SwipeableDrawer } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core/";
import { MapLegends } from "./MapLegends";

import { UserContext } from "../contexts/UserContext";

import FetchRxInfo from "./FetchRxInfo";
import FetchReg13URL from "./FetchReg13URL";

const { BaseLayer } = LayersControl;

const useStyles = makeStyles({
  table: {
    Width: 800,
  },
  paper: { Width: 900, padding: 5 },
  TableCell: { padding: "8px" },
});

const corsReducer = (filteredCors, action) => {
  switch (action.type) {
    case "initCors":
      return action.payload; // eslint-disable-next-line
    case "poi":
      return filteredCors.filter((fc) =>
        fc.status.toLowerCase().includes("poi")
      );
    case "exist":
      return filteredCors.filter((fc) =>
        fc.status.toLowerCase().includes("exist")
      );
    case "construct":
      return filteredCors.filter((fc) =>
        fc.status.toLowerCase().includes("construct")
      );
    case "planning":
      return filteredCors.filter((fc) =>
        fc.status.toLowerCase().includes("planning")
      );
    case "decomissioned":
      return filteredCors.filter((fc) =>
        fc.status.toLowerCase().includes("decomissioned")
      );
    case "toBeRelocated":
      return filteredCors.filter((fc) =>
        fc.status.toLowerCase().includes("toberelocated")
      );
    case "vsat":
      return action.payload;
    case "cenitex":
      return action.payload;
    case "broadband":
      return action.payload;
    case "mobile":
      return action.payload;
    case "dualcomms":
      return action.payload;
    case "dualSim":
      return action.payload;
    case "trimble":
      return action.payload;
    case "leica":
      return action.payload;
    case "sept":
      return action.payload;
    case "keyword":
      return action.payload;
    case "reset":
      return action.payload;
    case "vicgov":
      return action.payload;
    case "parksvic":
      return action.payload;
    case "cfa":
      return action.payload;
    case "council":
      return action.payload;
    case "education":
      return action.payload;
    case "waterauthority":
      return action.payload;
    case "community":
      return action.payload;
    case "corsnet-nsw":
      return action.payload;
    case "ga":
      return action.payload;
    case "redundancy":
      return action.payload;
    case "singleSim":
      return action.payload;
    case "netR9":
      return action.payload;
    case "gr50":
      return action.payload;
    case "alloy":
      return action.payload;
    case "polarx5":
      return action.payload;
    case "chipDelete":
      return filteredCors.filter((fc) => fc.code !== action.payload);
    case "clearFilterCors":
      return (filteredCors = []);
    case "replaceCors":
      return (filteredCors = action.payload);
    case "optuscomm":
      return (filteredCors = action.payload);
    case "telstracomm":
      return (filteredCors = action.payload);
    case "telstrasinglecomm":
      return (filteredCors = action.payload);
    case "optussinglecomm":
      return (filteredCors = action.payload);
    case "addCors":
      let newFilteredCors = action.payload;
      newFilteredCors.forEach((c) => {
        if (!filteredCors.includes(c)) {
          filteredCors.push(c);
        }
      });
      return filteredCors;
    case "trm5797100_none":
      return (filteredCors = action.payload);
    case "trm59800_none":
      return (filteredCors = action.payload);
    case "trm11500000_none":
      return (filteredCors = action.payload);
    case "leiar10_none":
      return (filteredCors = action.payload);
    case "leiar20_none":
      return (filteredCors = action.payload);
    case "leiar25_r3":
      return (filteredCors = action.payload);
    case "other_ant":
      return (filteredCors = action.payload);
    case "filteredPduCors":
      return (filteredCors = action.payload);
    case "filteredNoPduCors":
      return (filteredCors = action.payload);
    case "filteredUpsCors":
      return (filteredCors = action.payload);
    case "filteredNoUpsCors":
      return (filteredCors = action.payload);
    case "filteredSwitchCors":
      return (filteredCors = action.payload);
    case "filteredNoSwitchCors":
      return (filteredCors = action.payload);
    case "filterAtsCors":
      return (filteredCors = action.payload);
    case "filterNoAtsCors":
      return (filteredCors = action.payload);
    default:
      return filteredCors;
  }
};

const mrknsw = L.icon({
  iconUrl: "map-location-icon-blue.png",
  iconSize: [28, 40],
});
const mrkexisting = L.icon({
  iconUrl: "map-location-icon-green.png",
  iconSize: [28, 40],
});
const mrkproposed = L.icon({
  iconUrl: "map-location-icon-red.png",
  iconSize: [28, 40],
});
const mrkconstruct = L.icon({
  iconUrl: "map-location-icon-purple.png",
  iconSize: [28, 40],
});
const mrkga = L.icon({
  iconUrl: "map-location-icon-yellow.png",
  iconSize: [28, 40],
});
const mrkplanning = L.icon({
  iconUrl: "map-location-icon-dark.png",
  iconSize: [28, 40],
});
const mrkdecomissioned = L.icon({
  iconUrl: "map-location-icon-grey.png",
  iconSize: [28, 40],
});

const CorsMap = () => {
  const { user } = useContext(UserContext);
  const { apis } = useContext(ApiContext);
  const [filteredCors, dispatch] = useReducer(corsReducer, []);
  const { filteredCorsData } = useContext(CorsAzureMelContext);
  const [mergedData, setMergedData] = useState([]);
  let TotalCount = filteredCors.length;
  const [gpsMongoSite, setGpsMongoSite] = useState([]);
  const [loading, setLoading] = useState(false);

  const [vicGovCors, setVicGovCors] = useState([]);
  const [vicGovCount, setVicGovCount] = useState(0);

  const [parksVicCors, setParksVicCors] = useState([]);
  const [parksVicCount, setParksVicCount] = useState(0);

  const [cfaCors, setCfaCors] = useState([]);
  const [cfaCount, setCfaCount] = useState(0);

  const [councilCors, setCouncilCors] = useState([]);
  const [councilCount, setCouncilCount] = useState(0);

  const [educationCors, setEducationCors] = useState([]);
  const [educationCount, setEducationCount] = useState(0);

  const [waterAuthorityCors, setWaterAuthorityCors] = useState([]);
  const [waterAuthorityCount, setWaterAuthorityCount] = useState(0);

  const [communityCors, setCommunityCors] = useState([]);
  const [communityCount, setCommunityCount] = useState(0);

  const [nswCors, setNSWCors] = useState([]);
  const [nswCount, setNSWCount] = useState(0);

  const [gaCors, setGaCors] = useState([]);
  const [gaCount, setGaCount] = useState(0);

  const [vsatCors, setVsatCors] = useState([]);
  const [vsatCount, setVsatCount] = useState(0);

  const [cenitexCors, setCenitexCors] = useState([]);
  const [cenitexCount, setCenitexCount] = useState(0);

  const [broadbandCors, setBroadbandCors] = useState([]);
  const [broadbandCount, setBroadbandCount] = useState(0);

  const [mobileCors, setMobileCors] = useState([]);
  const [mobileCount, setMobileCount] = useState(0);

  const [singleSimCors, setSingleSimCors] = useState([]);
  const [singleSimCount, setSingleSimCount] = useState(0);

  const [redundancyCors, setRedundancyCors] = useState([]);
  const [redundancyCount, setRedundancyCount] = useState(0);

  const [dualCommCors, setDualCommCors] = useState([]);
  const [dualCommCount, setDualCommCount] = useState(0);

  const [dualSimCors, setDualSimCors] = useState([]);
  const [dualSimCount, setDualSimCount] = useState(0);

  const [trimbleCors, setTrimbleCors] = useState([]);
  const [trimbleCount, setTrimbleCount] = useState(0);

  const [leicaCors, setLeicaCors] = useState([]);
  const [leicaCount, setLeicaCount] = useState(0);

  const [septentrioCors, setseptentrioCors] = useState([]);
  const [septentrioCount, setseptentrioCount] = useState(0);

  const [netR9Cors, setNetR9Cors] = useState([]);
  const [NetR9Count, setNetR9Count] = useState(0);

  const [alloyCors, setAlloyCors] = useState([]);
  const [alloyCount, setAlloyCount] = useState(0);

  const [gr50Cors, setGr50Cors] = useState([]);
  const [gr50Count, setGr50Count] = useState(0);

  const [polarx5Cors, setPolarx5Cors] = useState([]);
  const [polarx5Count, setPolarx5Count] = useState(0);

  const [circuitId, setCircuitId] = useState("");

  const [existingCors, setExistingCors] = useState([]);
  const [poiCors, setPoiCors] = useState([]);
  const [npiCors, setNpiCors] = useState([]);
  const [constructingCors, setConstructiongCors] = useState([]);
  const [planningCors, setPlanningCors] = useState([]);
  const [decommissionedCors, setDecommissionedCors] = useState([]);
  const [pduCorsCount, setPduCorsCount] = useState(0);
  const [noPduCorsCount, setNoPduCorsCount] = useState(0);

  const [telstracomm, setTelstracomm] = useState([]);
  const [telstraSinglecomm, setTelstraSinglecomm] = useState([]);
  const [optuscomm, setOptuscomm] = useState([]);
  const [optusSinglecomm, setOptusSinglecomm] = useState([]);
  const [telstracommCount, setTelstracommCount] = useState(0);
  const [telstraSinglecommCount, setTelstraSinglecommCount] = useState(0);
  const [optuscommCount, setOptuscommCount] = useState(0);
  const [optusSinglecommCount, setOptusSinglecommCount] = useState(0);

  // below is antenna type count
  const [trm11500000_none, setTrm11500000_none] = useState([]); //TRM115000.00 NONE
  const [trm5797100_none, setTrm5797100_none] = useState([]); //TRM57971.00 NONE
  const [trm59800_none, setTrm59800_none] = useState([]); //TRM59800.00 NONE
  const [leiar10_none, setLeiar10_none] = useState([]); //LEIAR10 NONE
  const [leiar20_none, setLeiar20_none] = useState([]); //LEIAR20 NONE
  const [leiar25_r3, setLeiar25_r3] = useState([]); //LEIAR23 R3 LEIT
  const [other_ant, setOther_ant] = useState([]); //OTHER ANTENNA

  const [trm11500000_noneCount, setTrm11500000_noneCount] = useState(0); //TRM115000.00 NONE
  const [trm5797100_noneCount, setTrm5797100_noneCount] = useState(0); //TRM57971.00 NONE
  const [trm59800_noneCount, setTrm59800_noneCount] = useState(0); //TRM59800.00 NONE
  const [leiar10_noneCount, setLeiar10_noneCount] = useState(0); //LEIAR10 NONE
  const [leiar20_noneCount, setLeiar20_noneCount] = useState(0); //LEIAR20 NONE
  const [leiar25_r3Count, setLeiar25_r3Count] = useState(0); //LEIAR25 R3 LEIT
  const [other_antCount, setOther_antCount] = useState(0); //OTHER ANTENNA
  const bearer =
    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZTY1Y2Q2ZjAyNjA2MTUzM2NiZWVjMmI5ZmJhMGM5ZTRlODBlZTRlMDFmMmYxOGM3YzFmY2VmZGM4YzNjY2I2NDZjNWU2ZmViZDU3MjYwNjkiLCJpYXQiOjE2NTg4MDU3NTUuNzY1MjMxLCJuYmYiOjE2NTg4MDU3NTUuNzY1MjM1LCJleHAiOjIxMzIxOTEzNTUuNzUxMTExLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.qmftEyYDG-5T4MeYPy1kU3rL4nR7YL0GeMGCx18GzgMNGmxlkHbZ9CWdBV1NsuEKayUv0Pg4NIn4JeYPSs-jPnZA5gfV02v7LchplnNCkMNQNjZR4zqPXCciYgTzTKnz-8Moxn7Pou15fEHy3BWSe-3FD3dW_BGd17IqZ8EoyQWfkzNYS087L7ZQtVaXD_8TGbssDUZpCLETQQHYdHptpleuFkHrjvLhnPGvs9rbxrMVNp8RIfxYNxo5SgwyYYJupAaRl5fyDch7yCllM-5UCjanasC5e3xEq98ZIbWLiYwuBUpfq-AUxRL9_JG0U89eUAIgLRBcAx1JOopFZyEYeoyO7DUa5voQKZv0j5ElS-J1xaBtXJ3EgvznkIO6_2KI6i7u2Mhbh3ScTtsO7uObED7yc31H_gaiyUAF10wtDdw-60_BiN73EP-EaNs-tRgW6FdQ5TxlTAk4A4aADiTGUEhU2fvzWc-G5y-K1-E37NNEvraku-TSJr374N8IRpEx9dS9UCQVEbPJbAtEVNIjn6nG1D2ZGYaSUo-8r2mS_q-DmQ_54zYDfA3Oyfdr2j2dzkiwjnWpW3XILxdhF8-Ek9djiGqERK86XJBioaGv5ygaGhN3tYtYFa01oXZ2aAs9V-0MU4rqAkpeINoaBip-XcmOnIDAiwOcS4DcbblJjF8";

  useEffect(() => {
    fetchGpsMongoData(); // eslint-disable-next-line
    getPDUSite();
    getUpsSite();
    getSwitchSite();
    getAtsSite();
  }, []);

  useEffect(() => {
    mergeData(filteredCors); // eslint-disable-next-line
    getVicGovCount();
    getParksVicCount();
    getCfaCount();
    getCouncilCount();
    getEducationCount();
    getWaterAuthorityCount();
    getCommunityCount();
    getNSWCount();
    getGaCount();
    getMobileCount();
    getSingleSimCount();

    getRedundancyCount();
    getDualCommCount();
    getDualSimCount();

    getVsatCount();
    getCenitexCount();
    getBroadbandCount();

    getTrimbleCount();
    getLeicaCount();
    getseptentrioCount();

    getNetR9Count();
    getGr50Count();
    getAlloyCount();
    getPolarx5Count();
    getTelstracommCount();
    getOptuscommCount();
    getTelstraSingleCommCount();
    getOptusSingleCommCount();
    getTrm5797100_noneCount();
    getTrm59800_noneCount();
    getLeiar10_noneCount();
    getLeiar20_noneCount();
    getLeiar25_r3Count();
    getTrm11500000_noneCount();
    getOther_antCount();
    filterNoPduCors();
    filterPduCORS();
    filterNoUpsCors();
    filterUpsCORS();
    filterSwitchCors();
    filterNoSwitchCors();
    filterAtsCors();
    filterNoAtsCors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TotalCount, filteredCors]);

  useEffect(() => {
    // dispatch({ type: "initCors", payload: gpsMongoSite });
    // mergeData();
    let data = mergeData(gpsMongoSite);
    dispatch({ type: "initCors", payload: data });

    setLoading(!loading);
    getExistingCors();
    getPOI();
    getNPI();
    getConstructing();
    getPlanning();
    getDecommission();
    getTelstracomm();
    getOptuscomm();
    getTelstraSingleComm();
    getOptusSingleComm();
    getTrm11500000_noneCount();
    getTrm5797100_noneCount();
    getTrm59800_noneCount();
    getLeiar20_noneCount();
    getLeiar25_r3Count();
    getLeiar10_noneCount(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsMongoSite]);

  const fetchGpsMongoData = async () => {
    // eslint-disable-next-line
    const data = await Axios.get(apis.gpsCMS_API + "/cors?_limit=-1").then(
      (res) => {
        setGpsMongoSite(res.data);
        // mergeData();
      }
    );
  };

  const mergeData = (orginalData) => {
    if (orginalData === undefined) orginalData = [];
    let merged = [];
    // let orginalData = orginalData;
    for (let i = 0; i < orginalData.length; i++) {
      merged.push({
        ...orginalData[i],
        ...filteredCorsData.find((d) => d.code === orginalData[i].code),
      });
    }
    setMergedData(merged);
    return merged;
  };

  let PoiCount = filteredCors.filter((fc) =>
    fc.status.toLowerCase().includes("poi")
  ).length;
  let ExistCount = filteredCors.filter((fc) =>
    fc.status.toLowerCase().includes("existing")
  ).length;
  let constructingCount = filteredCors.filter((fc) =>
    fc.status.toLowerCase().includes("constructing")
  ).length;
  let TobeRelocatedCount = filteredCors.filter((fc) =>
    fc.status.toLowerCase().includes("toberelocated")
  ).length;
  let planningCount = filteredCors.filter((fc) =>
    fc.status.toLowerCase().includes("planning")
  ).length;
  let decomissionedCount = filteredCors.filter((fc) =>
    fc.status.toLowerCase().includes("decomissioned")
  ).length;

  const getVicGovCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("vicgov")) {
          count++;
          cors.push(e);
        }
      }
    });
    setVicGovCount(count);
    setVicGovCors(cors);
  };

  const getParksVicCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("park")) {
          count++;
          cors.push(e);
        }
      }
    });
    setParksVicCount(count);
    setParksVicCors(cors);
  };

  const getCfaCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("cfa")) {
          count++;
          cors.push(e);
        }
      }
    });
    setCfaCount(count);
    setCfaCors(cors);
  };

  const getCouncilCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("council")) {
          count++;
          cors.push(e);
        }
      }
    });
    setCouncilCount(count);
    setCouncilCors(cors);
  };

  const getEducationCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("education")) {
          count++;
          cors.push(e);
        }
      }
    });
    setEducationCount(count);
    setEducationCors(cors);
  };

  const getWaterAuthorityCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("water authority")) {
          count++;
          cors.push(e);
        }
      }
    });
    setWaterAuthorityCount(count);
    setWaterAuthorityCors(cors);
  };

  const getCommunityCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("community")) {
          count++;
          cors.push(e);
        }
      }
    });
    setCommunityCount(count);
    setCommunityCors(cors);
  };

  const getNSWCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("nsw")) {
          count++;
          cors.push(e);
        }
      }
    });
    setNSWCount(count);
    setNSWCors(cors);
  };

  const getGaCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.host != null) {
        if (e.host.name.toLowerCase().includes("ga")) {
          count++;
          cors.push(e);
        }
      }
    });
    setGaCount(count);
    setGaCors(cors);
  };

  const getVsatCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length !== 0) {
        e.comms.forEach((f) => {
          if (f.name.toLowerCase().includes("vsat")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setVsatCount(count);
    setVsatCors(cors);
  };

  const getCenitexCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length !== 0) {
        e.comms.forEach((f) => {
          if (f.name.toLowerCase().includes("cenitex")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setCenitexCount(count);
    setCenitexCors(cors);
  };

  const getBroadbandCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length !== 0) {
        e.comms.forEach((f) => {
          if (
            f.name.toLowerCase().includes("adsl") ||
            f.name.toLowerCase().includes("nbn") ||
            f.name.toLowerCase().includes("host")
          ) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setBroadbandCount(count);
    setBroadbandCors(cors);
  };

  const getMobileCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length > 0) {
        if (e.comms[0].name.toLowerCase().includes("sim")) {
          count++;
          cors.push(e);
        }
      }
    });
    setMobileCount(count);
    setMobileCors(cors);
  };

  const getSingleSimCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length === 1) {
        if (e.comms[0].name.toLowerCase().includes("sim")) {
          count++;
          cors.push(e);
        }
      }
    });
    setSingleSimCount(count);
    setSingleSimCors(cors);
  };

  const getRedundancyCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms_redundancy != null) {
        if (e.comms_redundancy == "yes") {
          count++;
          cors.push(e);
        }
      }
    });
    setRedundancyCount(count);
    setRedundancyCors(cors);
  };
  const getDualCommCount = () => {
    let count = 0;
    let hostDualCount = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length > 1 && e.comms_redundancy != null) {
        if (
          e.comms_redundancy == "yes" &&
          (!e.comms[0].name.toLowerCase().includes("sim") ||
            !e.comms[1].name.toLowerCase().includes("sim"))
        ) {
          count++;
          cors.push(e);
        }
      }
      count = count + hostDualCount;
    });
    setDualCommCount(count);
    setDualCommCors(cors);
  };

  const getDualSimCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.comms.length > 1) {
        if (
          e.comms_redundancy == "yes" &&
          e.comms[0].name.toLowerCase().includes("sim") &&
          e.comms[1].name.toLowerCase().includes("sim")
        ) {
          count++;
          cors.push(e);
        }
      }
    });
    setDualSimCount(count);
    setDualSimCors(cors);
  };

  const getTrimbleCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("trimble")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setTrimbleCount(count);
    setTrimbleCors(cors);
  };

  const getLeicaCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("leica")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setLeicaCount(count);
    setLeicaCors(cors);
  };

  const getseptentrioCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("septentrio")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setseptentrioCount(count);
    setseptentrioCors(cors);
  };

  const getNetR9Count = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("netr9")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setNetR9Count(count);
    setNetR9Cors(cors);
  };

  const getAlloyCount = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("alloy")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setAlloyCount(count);
    setAlloyCors(cors);
  };

  const getGr50Count = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("gr50")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setGr50Count(count);
    setGr50Cors(cors);
  };

  const getPolarx5Count = () => {
    let count = 0;
    let cors = [];
    filteredCors.forEach((e) => {
      if (e.gnss_receivers != null) {
        e.gnss_receivers.forEach((f) => {
          if (f.name.toLowerCase().includes("polarx5")) {
            count++;
            cors.push(e);
          }
        });
      }
    });
    setPolarx5Count(count);
    setPolarx5Cors(cors);
  };

  const [paneState, setPaneState] = useState(false);
  const anchor = "left";
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setPaneState({ ...paneState, [anchor]: open });
  };

  const [assetDialogOpen, setAssetDialogOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetLoading, setAssetLoading] = useState(false);
  const classes = useStyles();
  const [selectCors, setSelectCors] = useState("");

  useEffect(() => {
    setAssetLoading(!assetLoading); // eslint-disable-next-line
  }, [assets]);

  const handleCorsAssetDialogOpen = (cors_id) => {
    setAssetDialogOpen(true);
    setSelectCors(cors_id);
    setCircuitId("");
  };

  const fetchAsset = async (code) => {
    var options = {
      method: "GET",
      url: `https://asset.vicpos.com.au/api/v1/hardware`,
      params: { search: code },
      headers: {
        Accept: "application/json",
        Authorization: `${bearer}`,
      },
    };
    await axios
      .request(options)
      .then(function (response) {
        var assetData = response.data.rows;
        var BreakException = {};

        var filteredAssetData = assetData.filter(
          (ad) =>
            ad.name.includes(code) && ad.status_label.status_meta === "deployed"
        );

        setAssets(filteredAssetData);
        try {
          filteredAssetData.forEach((a) => {
            if (
              a["custom_fields"].hasOwnProperty(["Optus circuit ID/username"])
            ) {
              if (
                a["custom_fields"]["Optus circuit ID/username"].value !== null
              ) {
                setCircuitId(
                  a["custom_fields"]["Optus circuit ID/username"].value
                );
                throw BreakException;
              }
            }
          });
        } catch (error) {
          if (error !== BreakException) throw error;
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getPDUSite = async () => {
    let cors = [];
    var options = {
      method: "GET",
      url: `https://asset.vicpos.com.au/api/v1/hardware?category_id=19&status=Deployed`,
      headers: {
        Accept: "application/json",
        Authorization: `${bearer}`,
      },
    };
    await axios.request(options).then(function (response) {
      var pduSite = response.data.rows;
      pduSite.forEach((c) => {
        cors.push(c.name.substring(0, 4));
      });
      setFilteredPduSite(cors);
      setPduCorsCount(cors.length);
      setNoPduCorsCount(filteredCors.length - cors.length);
    });
  };

  const filterPduCORS = () => {
    let pduCors = [];
    // push to pduCors if filteredCors code is in filteredPduSite
    filteredCors.forEach((c) => {
      if (filteredPduSite.includes(c.code.substring(0, 4))) {
        pduCors.push(c);
      }
    });
    // dispatch({ type: "filteredPduCors", payload: pduCors });
    setPduCorsCount(pduCors.length);
    return pduCors;
  };

  const filterNoPduCors = () => {
    let noPduCors = [];
    // push to noPduCors if filteredCors code is not in filteredPduSite
    filteredCors.forEach((c) => {
      if (!filteredPduSite.includes(c.code.substring(0, 4))) {
        noPduCors.push(c);
      }
    });
    // dispatch({ type: "filteredNoPduCors", payload: noPduCors });
    setNoPduCorsCount(noPduCors.length);
    return noPduCors;
  };

  // wip ups site
  const [upsSite, setUpsSite] = useState([]);
  const [upsCorsCount, setUpsCorsCount] = useState(0);
  const [noUpsCorsCount, setNoUpsCorsCount] = useState(0);

  const getUpsSite = async () => {
    let cors = [];
    var options = {
      method: "GET",
      url: `https://asset.vicpos.com.au/api/v1/hardware?category_id=25&status=Deployed`,
      headers: {
        Accept: "application/json",
        Authorization: `${bearer}`,
      },
    };
    await axios.request(options).then(function (response) {
      var upsSite = response.data.rows;
      upsSite.forEach((c) => {
        cors.push(c.name.substring(0, 4));
      });
      setUpsSite(cors);
      setUpsCorsCount(cors.length);
      setNoUpsCorsCount(filteredCors.length - cors.length);
    });
  };
  const filterUpsCORS = () => {
    let upsCors = [];
    // push to upsCors if filteredCors code is in upsSite
    filteredCors.forEach((c) => {
      if (upsSite.includes(c.code.substring(0, 4))) {
        upsCors.push(c);
      }
    });
    setUpsCorsCount(upsCors.length);
    return upsCors;
  };

  const filterNoUpsCors = () => {
    let noUpsCors = [];
    // push to noUpsCors if filteredCors code is not in upsSite
    filteredCors.forEach((c) => {
      if (!upsSite.includes(c.code.substring(0, 4))) {
        noUpsCors.push(c);
      }
    });
    setNoUpsCorsCount(noUpsCors.length);
    return noUpsCors;
  };

  const [switchSite, setSwitchSite] = useState([]);
  const [switchSiteCount, setSwitchSiteCount] = useState(0);
  const [noSwitchSiteCount, setNoSwitchSiteCount] = useState(0);
  const getSwitchSite = async () => {
    let cors = [];
    var options = {
      method: "GET",
      url: `https://asset.vicpos.com.au/api/v1/hardware?category_id=23&status=Deployed`,
      headers: {
        Accept: "application/json",
        Authorization: `${bearer}`,
      },
    };
    await axios.request(options).then(function (response) {
      var switchSite = response.data.rows;
      switchSite.forEach((c) => {
        cors.push(c.name.substring(0, 4));
      });
      setSwitchSite(cors);
      setSwitchSiteCount(cors.length);
      setNoSwitchSiteCount(filteredCors.length - cors.length);
    });
  };

  const filterSwitchCors = () => {
    let switchCors = [];
    // push to switchCors if filteredCors code is in switchSite
    filteredCors.forEach((c) => {
      if (switchSite.includes(c.code.substring(0, 4))) {
        switchCors.push(c);
      }
    });
    setSwitchSiteCount(switchCors.length);
    return switchCors;
  };

  const filterNoSwitchCors = () => {
    let noSwitchCors = [];
    // push to noSwitchCors if filteredCors code is not in switchSite
    filteredCors.forEach((c) => {
      if (!switchSite.includes(c.code.substring(0, 4))) {
        noSwitchCors.push(c);
      }
    });
    setNoSwitchSiteCount(noSwitchCors.length);
    return noSwitchCors;
  };

  const [atsSite, setAtsSite] = useState([]);
  const [atsSiteCount, setAtsSiteCount] = useState(0);
  const [noAtsSiteCount, setNoAtsSiteCount] = useState(0);
  const getAtsSite = async () => {
    let cors = [];
    var options = {
      method: "GET",
      url: `https://asset.vicpos.com.au/api/v1/hardware?category_id=36&status=Deployed`,
      headers: {
        Accept: "application/json",
        Authorization: `${bearer}`,
      },
    };
    await axios.request(options).then(function (response) {
      var atsSite = response.data.rows;
      atsSite.forEach((c) => {
        cors.push(c.name.substring(0, 4));
      });
      setAtsSite(cors);
      setAtsSiteCount(cors.length);
      setNoAtsSiteCount(filteredCors.length - cors.length);
    });
  };

  const filterAtsCors = () => {
    let atsCors = [];
    // push to atsCors if filteredCors code is in atsSite
    filteredCors.forEach((c) => {
      if (atsSite.includes(c.code.substring(0, 4))) {
        atsCors.push(c);
      }
    });
    setAtsSiteCount(atsCors.length);
    return atsCors;
  };

  const filterNoAtsCors = () => {
    let noAtsCors = [];
    // push to noAtsCors if filteredCors code is not in atsSite
    filteredCors.forEach((c) => {
      if (!atsSite.includes(c.code.substring(0, 4))) {
        noAtsCors.push(c);
      }
    });
    setNoAtsSiteCount(noAtsCors.length);
    return noAtsCors;
  };

  // end of wip

  const handleCorsAssetDialogClose = () => {
    setAssetDialogOpen(false);
    setAssets([]);
  };

  const [remainCors, setRemainCors] = useState([]);
  const [corsDialogOpen, setCorsDialogOpen] = useState(false);
  const [filteredPduSite, setFilteredPduSite] = useState([]);

  const handleCorsDialogOpen = () => {
    setCorsDialogOpen(true);
  };
  const handleCorsDialogClose = () => {
    setCorsDialogOpen(false);
  };

  const searchTerm = useRef("something");

  const searchHandler = (st) => {
    const newList = filteredCors.filter((cors) => {
      return Object.values(cors)
        .join(" ")
        .toLowerCase()
        .includes(st.toLowerCase());
    });
    dispatch({ type: "keyword", payload: newList });
  };

  const resetHandler = () => {
    let data = mergeData(gpsMongoSite);
    dispatch({ type: "initCors", payload: data });
    searchTerm.current.value = "";
    mergeData();
  };

  const getExistingCors = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.status.toLowerCase() === "existing") {
        cors.push(c);
      }
    });
    setExistingCors(cors);
  };
  const getPOI = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.status.toLowerCase() === "poi") {
        cors.push(c);
      }
    });
    setPoiCors(cors);
  };
  const getConstructing = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.status.toLowerCase() === "constructing") {
        cors.push(c);
      }
    });
    setConstructiongCors(cors);
  };
  const getPlanning = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.status.toLowerCase() === "planning") {
        cors.push(c);
      }
    });
    setPlanningCors(cors);
  };
  const getDecommission = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.status.toLowerCase() === "decomissioned") {
        cors.push(c);
      }
    });
    setDecommissionedCors(cors);
  };
  const getNPI = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.host.name.toLowerCase() === "ga") {
        cors.push(c);
      }
    });
    setNpiCors(cors);
  };

  const getTelstracomm = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.comms_redundancy == "yes" && c.status == "existing") {
        if (c.comms[0].name.toLowerCase().includes("telstra")) {
          cors.push(c);
        }
      }
    });
    setTelstracomm(cors);
  };
  const getTelstracommCount = () => {
    let count = 0;
    filteredCors.forEach((c) => {
      if (c.comms_redundancy == "yes" && c.status == "existing") {
        if (c.comms[0].name.toLowerCase().includes("telstra")) {
          count++;
        }
      }
    });
    setTelstracommCount(count);
  };

  const getOptuscomm = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.comms_redundancy == "yes" && c.status == "existing") {
        if (c.comms[0].name.toLowerCase().includes("optus")) {
          cors.push(c);
        }
      }
    });
    setOptuscomm(cors);
  };

  const getOptuscommCount = () => {
    let count = 0;
    filteredCors.forEach((c) => {
      if (c.comms_redundancy == "yes" && c.status == "existing") {
        if (c.comms[0].name.toLowerCase().includes("optus")) {
          count++;
        }
      }
    });
    setOptuscommCount(count);
  };

  const getTelstraSingleComm = () => {
    let cors = [];
    gpsMongoSite.forEach((c) => {
      if (c.comms_redundancy == "no") {
        if (
          c.comms[0] != undefined &&
          c.comms[0].name.toLowerCase().includes("telstra")
        ) {
          cors.push(c);
        }
      }
    });
    setTelstraSinglecomm(cors);
  };

  const getTelstraSingleCommCount = () => {
    let count = 0;
    filteredCors.forEach((c) => {
      if (c.comms_redundancy == "no") {
        if (
          c.comms[0] != undefined &&
          c.comms[0].name.toLowerCase().includes("telstra")
        ) {
          count++;
        }
      }
    });
    setTelstraSinglecommCount(count);
  };
  const getOptusSingleComm = () => {
    let cors = [];
    filteredCors.forEach((c) => {
      if (c.comms_redundancy == "no") {
        if (
          c.comms[0] != undefined &&
          c.comms[0].name.toLowerCase().includes("optus")
        ) {
          cors.push(c);
        }
      }
    });
    setOptusSinglecomm(cors);
  };

  const getOptusSingleCommCount = () => {
    let count = 0;
    filteredCors.forEach((c) => {
      if (c.comms_redundancy == "no") {
        if (
          c.comms[0] != undefined &&
          c.comms[0].name.toLowerCase().includes("optus")
        ) {
          count++;
        }
      }
    });
    setOptusSinglecommCount(count);
  };

  // filter antenna type
  const getTrm5797100_noneCount = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (c.antenna.toLowerCase().includes("trm57971")) {
          count++;
          obj.push(c);
        }
      }
    });
    setTrm5797100_noneCount(count);
    setTrm5797100_none(obj);
  };
  const getTrm59800_noneCount = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (c.antenna.toLowerCase().includes("trm59800")) {
          count++;
          obj.push(c);
        }
      }
    });
    setTrm59800_noneCount(count);
    setTrm59800_none(obj);
  };

  const getLeiar10_noneCount = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (c.antenna.toLowerCase().includes("leiar10")) {
          count++;
          obj.push(c);
        }
      }
    });
    setLeiar10_noneCount(count);
    setLeiar10_none(obj);
  };

  const getTrm11500000_noneCount = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (c.antenna.toLowerCase().includes("trm115000")) {
          count++;
          obj.push(c);
        }
      }
    });
    setTrm11500000_noneCount(count);
    setTrm11500000_none(obj);
  };

  const getLeiar20_noneCount = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (c.antenna.toLowerCase().includes("leiar20")) {
          count++;
          obj.push(c);
        }
      }
    });
    setLeiar20_noneCount(count);
    setLeiar20_none(obj);
  };

  const getLeiar25_r3Count = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (c.antenna.toLowerCase().includes("leiar25")) {
          count++;
          obj.push(c);
        }
      }
    });
    setLeiar25_r3Count(count);
    setLeiar25_r3(obj);
  };

  const getOther_antCount = () => {
    let count = 0;
    let obj = [];
    filteredCors.forEach((c) => {
      if (c.antenna != null) {
        if (
          !c.antenna.toLowerCase().includes("trm115000") &&
          !c.antenna.toLowerCase().includes("trm57971") &&
          !c.antenna.toLowerCase().includes("trm59800") &&
          !c.antenna.toLowerCase().includes("leiar20") &&
          !c.antenna.toLowerCase().includes("leiar25") &&
          !c.antenna.toLowerCase().includes("leiar10")
        ) {
          count++;
          obj.push(c);
        }
      }
    });
    // setFilteredCors(obj);
    setOther_antCount(count);
    setOther_ant(obj);
  };

  return loading ? (
    <div className="spinner">
      <Spinner animation="border" variant="primary" />
      Loading...
    </div>
  ) : (
    <div style={{ padding: "20px" }}>
      <h3>
        GPSnet CORS Map{" "}
        <sup>
          <Badge variant="primary">{TotalCount}</Badge>
        </sup>
      </h3>
      <div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <div style={{ margin: "10px" }}>
            <TextField
              aria-describedby="basic-addon1"
              label="Keyword"
              id="keyword"
              inputRef={searchTerm}
              onChange={() => searchHandler(searchTerm.current.value)}
              margin="5px"
              variant="outlined"
              style={{ width: "100%", marginBottom: "5px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={resetHandler}
              style={{ marginLeft: "5px", alignItems: "center" }}>
              Reset
            </Button>

            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: "5px", alignItems: "center" }}
              onClick={toggleDrawer(anchor, true)}>
              More Filter
            </Button>
            <SwipeableDrawer
              anchor={anchor}
              open={paneState[anchor]}
              onClose={toggleDrawer(anchor, false)}>
              <div>
                <Chip label="CORS Sites" />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "poi" })}
                  style={{ margin: "5px" }}
                  disabled={PoiCount === 0}>
                  Point of Interest{" "}
                  <sup>
                    <Badge variant="danger">{PoiCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "exist" })}
                  style={{ margin: "5px" }}
                  disabled={ExistCount === 0}>
                  Existing{" "}
                  <sup>
                    <Badge variant="danger">{ExistCount}</Badge>
                  </sup>
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "construct" })}
                  style={{ margin: "5px" }}
                  disabled={constructingCount === 0}>
                  Constructing&nbsp;
                  <sup>
                    <Badge variant="danger">{constructingCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "planning" })}
                  style={{ margin: "5px" }}
                  disabled={planningCount === 0}>
                  Planning&nbsp;
                  <sup>
                    <Badge variant="danger">{planningCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "decomissioned" })}
                  style={{ margin: "5px" }}
                  disabled={decomissionedCount === 0}>
                  Decomissioned&nbsp;
                  <sup>
                    <Badge variant="danger">{decomissionedCount}</Badge>
                  </sup>
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "toBeRelocated" })}
                  style={{ margin: "5px" }}
                  disabled={TobeRelocatedCount === 0}>
                  To be Relocated&nbsp;
                  <sup>
                    <Badge variant="danger">{TobeRelocatedCount}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                <Chip label="Host Types" />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "vicgov", payload: vicGovCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={vicGovCount === 0}>
                  VicGov&nbsp;
                  <sup>
                    <Badge variant="danger">{vicGovCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "parksvic", payload: parksVicCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={parksVicCount === 0}>
                  Parks Vic&nbsp;
                  <sup>
                    <Badge variant="danger">{parksVicCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "cfa", payload: cfaCors })}
                  style={{ margin: "5px" }}
                  disabled={cfaCount === 0}>
                  CFA&nbsp;
                  <sup>
                    <Badge variant="danger">{cfaCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "council", payload: councilCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={councilCount === 0}>
                  Council&nbsp;
                  <sup>
                    <Badge variant="danger">{councilCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "education", payload: educationCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={educationCount === 0}>
                  Education&nbsp;
                  <sup>
                    <Badge variant="danger">{educationCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({
                      type: "waterauthority",
                      payload: waterAuthorityCors,
                    })
                  }
                  style={{ margin: "5px" }}
                  disabled={waterAuthorityCount === 0}>
                  Water Authority&nbsp;
                  <sup>
                    <Badge variant="danger">{waterAuthorityCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "community", payload: communityCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={communityCount === 0}>
                  Community&nbsp;
                  <sup>
                    <Badge variant="danger">{communityCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "corsnet-nsw", payload: nswCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={nswCount === 0}>
                  CORSNet-NSW&nbsp;
                  <sup>
                    <Badge variant="danger">{nswCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "ga", payload: gaCors })}
                  style={{ margin: "5px" }}
                  disabled={gaCount === 0}>
                  NPI&nbsp;
                  <sup>
                    <Badge variant="danger">{gaCount}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                {" "}
                <Badge></Badge>
                <Chip label="Comms Types" />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch({ type: "vsat", payload: vsatCors })}
                  style={{ margin: "5px" }}
                  disabled={vsatCount === 0}>
                  VSAT&nbsp;
                  <sup>
                    <Badge variant="danger">{vsatCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "cenitex", payload: cenitexCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={cenitexCount === 0}>
                  CenITex{" "}
                  <sup>
                    <Badge variant="danger">{cenitexCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "broadband", payload: broadbandCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={broadbandCount === 0}>
                  Broadband{" "}
                  <sup>
                    <Badge variant="danger">{broadbandCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "mobile", payload: mobileCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={mobileCount === 0}>
                  Mobile Network{" "}
                  <sup>
                    <Badge variant="danger">{mobileCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "singleSim", payload: singleSimCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={singleSimCount === 0}>
                  Single SIM{" "}
                  <sup>
                    <Badge variant="danger">{singleSimCount}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                <Chip label="Redundancy Comms" />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "redundancy", payload: redundancyCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={redundancyCount === 0}>
                  Redundancy{" "}
                  <sup>
                    <Badge variant="danger">{redundancyCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "dualcomms", payload: dualCommCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={dualCommCount === 0}>
                  Dual Comms{" "}
                  <sup>
                    <Badge variant="danger">{dualCommCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    dispatch({ type: "dualSim", payload: dualSimCors })
                  }
                  style={{ margin: "5px" }}
                  disabled={dualSimCount === 0}>
                  Dual SIM{" "}
                  <sup>
                    <Badge variant="danger">{dualSimCount}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                <Chip label="Receiver Brand" />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "trimble", payload: trimbleCors })
                  }
                  disabled={trimbleCount === 0}>
                  Trimble{" "}
                  <sup>
                    <Badge variant="danger">{trimbleCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "leica", payload: leicaCors })
                  }
                  disabled={leicaCount === 0}>
                  Leica{" "}
                  <sup>
                    <Badge variant="danger">{leicaCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "sept", payload: septentrioCors })
                  }
                  disabled={septentrioCount === 0}>
                  Septentrio{" "}
                  <sup>
                    <Badge variant="danger">{septentrioCount}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                <Chip label="Receiver Type" />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() => dispatch({ type: "gr50", payload: gr50Cors })}
                  disabled={gr50Count === 0}>
                  Leica GR50{" "}
                  <sup>
                    <Badge variant="danger">{gr50Count}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "alloy", payload: alloyCors })
                  }
                  disabled={alloyCount === 0}>
                  Trimble Alloy{" "}
                  <sup>
                    <Badge variant="danger">{alloyCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "netR9", payload: netR9Cors })
                  }
                  disabled={NetR9Count === 0}>
                  Trimble NetR9{" "}
                  <sup>
                    <Badge variant="danger">{NetR9Count}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "polarx5", payload: polarx5Cors })
                  }
                  disabled={polarx5Count === 0}>
                  septentrio Polarx5{" "}
                  <sup>
                    <Badge variant="danger">{polarx5Count}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                <Chip label="Primary Comms" />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "telstracomm", payload: telstracomm })
                  }
                  disabled={telstracommCount === 0}>
                  Telstra{" "}
                  <sup>
                    <Badge variant="danger">{telstracommCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({ type: "optuscomm", payload: optuscomm })
                  }
                  disabled={optuscommCount === 0}>
                  Optus{" "}
                  <sup>
                    <Badge variant="danger">{optuscommCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "telstrasinglecomm",
                      payload: telstraSinglecomm,
                    })
                  }
                  disabled={telstraSinglecommCount === 0}>
                  Telstra Single{" "}
                  <sup>
                    <Badge variant="danger">{telstraSinglecommCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "optussinglecomm",
                      payload: optusSinglecomm,
                    })
                  }
                  disabled={optusSinglecommCount === 0}>
                  Optus Single{" "}
                  <sup>
                    <Badge variant="danger">{optusSinglecommCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetHandler}
                  style={{ marginLeft: "5px" }}>
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={toggleDrawer(anchor, false)}
                  style={{ marginLeft: "5px" }}>
                  X
                </Button>
              </div>
              <div>
                <Chip label="Ant Type" />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "trm5797100_none",
                      payload: trm5797100_none,
                    })
                  }
                  disabled={trm5797100_noneCount === 0}>
                  TRM57971.00 NONE{" "}
                  <sup>
                    <Badge variant="danger">{trm5797100_noneCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "trm59800_none",
                      payload: trm59800_none,
                    })
                  }
                  disabled={trm59800_noneCount === 0}>
                  TRM59800.00 NONE{" "}
                  <sup>
                    <Badge variant="danger">{trm59800_noneCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "trm11500000_none",
                      payload: trm11500000_none,
                    })
                  }
                  disabled={trm11500000_noneCount === 0}>
                  TRM115000.00 NONE{" "}
                  <sup>
                    <Badge variant="danger">{trm11500000_noneCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "leiar10_none",
                      payload: leiar10_none,
                    })
                  }
                  disabled={leiar10_noneCount === 0}>
                  Leiar10 NONE{" "}
                  <sup>
                    <Badge variant="danger">{leiar10_noneCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "leiar20_none",
                      payload: leiar20_none,
                    })
                  }
                  disabled={leiar20_noneCount === 0}>
                  Leiar20 NONE{" "}
                  <sup>
                    <Badge variant="danger">{leiar20_noneCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "leiar25_r3",
                      payload: leiar25_r3,
                    })
                  }
                  disabled={leiar25_r3Count === 0}>
                  Leiar25.R3 LEIT{" "}
                  <sup>
                    <Badge variant="danger">{leiar25_r3Count}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px" }}
                  onClick={() =>
                    dispatch({
                      type: "other_ant",
                      payload: other_ant,
                    })
                  }
                  disabled={other_antCount === 0}>
                  Other Antenna{" "}
                  <sup>
                    <Badge variant="danger">{other_antCount}</Badge>
                  </sup>
                </Button>
              </div>
              <div>
                <Chip label="Equipment Type" />
                {/* wip */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filteredPduCors",
                      payload: filterPduCORS(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={pduCorsCount === 0}>
                  PDU&nbsp;
                  <sup>
                    <Badge variant="danger">{pduCorsCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filteredNoPduCors",
                      payload: filterNoPduCors(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={noPduCorsCount === 0}>
                  No PDU&nbsp;
                  <sup>
                    <Badge variant="danger">{noPduCorsCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filteredUpsCors",
                      payload: filterUpsCORS(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={upsCorsCount === 0}>
                  UPS&nbsp;
                  <sup>
                    <Badge variant="danger">{upsCorsCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filteredNoUpsCors",
                      payload: filterNoUpsCors(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={noUpsCorsCount === 0}>
                  No UPS&nbsp;
                  <sup>
                    <Badge variant="danger">{noUpsCorsCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filteredSwitchCors",
                      payload: filterSwitchCors(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={switchSiteCount === 0}>
                  Switch&nbsp;
                  <sup>
                    <Badge variant="danger">{switchSiteCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filteredNoSwitchCors",
                      payload: filterNoSwitchCors(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={noSwitchSiteCount === 0}>
                  No Switch&nbsp;
                  <sup>
                    <Badge variant="danger">{noSwitchSiteCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filterAtsCors",
                      payload: filterAtsCors(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={atsSiteCount === 0}>
                  ATS&nbsp;
                  <sup>
                    <Badge variant="danger">{atsSiteCount}</Badge>
                  </sup>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: "filterNoAtsCors",
                      payload: filterNoAtsCors(),
                    });
                  }}
                  style={{ margin: "5px" }}
                  disabled={noAtsSiteCount === 0}>
                  no ATS&nbsp;
                  <sup>
                    <Badge variant="danger">{noAtsSiteCount}</Badge>
                  </sup>
                </Button>
                {/* end of wip */}
              </div>
            </SwipeableDrawer>
          </div>
        </Form>
      </div>

      {/* map legends */}
      <MapLegends />
      {/* end of map legends */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Layer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Button
              onClick={() => {
                dispatch({ type: "addCors", payload: existingCors });
                mergeData();
              }}>
              existing
            </Button>
            <Button
              onClick={() => {
                dispatch({ type: "addCors", payload: poiCors });
                mergeData();
              }}>
              poi
            </Button>

            <Button
              onClick={() => {
                dispatch({ type: "addCors", payload: planningCors });
                mergeData();
              }}>
              planning
            </Button>
            <Button
              onClick={() => {
                dispatch({ type: "addCors", payload: constructingCors });
                mergeData();
              }}>
              constructing
            </Button>
            <Button
              onClick={() => {
                dispatch({ type: "addCors", payload: npiCors });
                mergeData();
              }}>
              NPI
            </Button>
            <Button
              onClick={() => {
                dispatch({
                  type: "addCors",
                  payload: decommissionedCors,
                });
                mergeData();
              }}>
              decommissioned
            </Button>

            <Button
              onClick={() => dispatch({ type: "clearFilterCors" })}
              variant="outlined"
              color="secondary">
              Clear map
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>CORS List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ textAlign: "left" }}>
            <Button
              onClick={handleCorsDialogOpen}
              variant="outlined"
              color="primary"
              style={{ marginRight: "5px" }}>
              cors picker
            </Button>
            <Button
              onClick={() => dispatch({ type: "clearFilterCors" })}
              variant="outlined"
              color="secondary">
              Clear map
            </Button>
            <br />
            {mergedData.map((cors) => (
              <Chip
                id={cors.code}
                label={cors.name}
                avatar={
                  <Avatar
                    variant="rounded"
                    style={{
                      height: "20px",
                      width: "50px",
                      backgroundColor: "#1a6985",
                    }}>
                    {cors.code}
                  </Avatar>
                }
                onDelete={() => {
                  dispatch({ type: "chipDelete", payload: cors.code });
                  setRemainCors((oldArray) => [...oldArray, cors]);
                }}
                style={{
                  margin: "5px",
                  height: "30px",
                  backgroundColor:
                    cors.status.toLowerCase() === "poi"
                      ? "#FF6373"
                      : cors.status.toLowerCase() === "constructing"
                      ? "#CA22CE"
                      : cors.host.name.toLowerCase() === "corsnet-nsw"
                      ? "#2596be"
                      : cors.host.name.toLowerCase() === "ga"
                      ? "#FFBE57"
                      : cors.status.toLowerCase() === "planning"
                      ? "#000A05"
                      : cors.status.toLowerCase() === "decomissioned"
                      ? "#A0AEAD"
                      : "#2DB651",
                }}
                color="primary"
                onClick={() => {
                  if (user.username) {
                    handleCorsAssetDialogOpen(cors.name);
                    fetchAsset(cors.code);
                  }
                }}></Chip>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <Map
        center={[-36.59184868, 144.7982048]}
        zoom={7}
        style={{ height: "600px" }}>
        <LayersControl>
          <BaseLayer checked name="Open Street Map">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </BaseLayer>
          <BaseLayer name="ArcGIS Map">
            <TileLayer
              url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution='&copy <a href="http://www.esri.com/">Esri</a> i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community '
            />
          </BaseLayer>
          <BaseLayer name="Esri World Street Map">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
            />
          </BaseLayer>
          <BaseLayer name="Open Topo Map">
            <TileLayer
              url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              maxNativeZoom={15}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            />
          </BaseLayer>
          {mergedData.map((cors) => (
            <Marker
              position={[cors.lat, cors.lon]}
              key={cors.code}
              icon={
                cors.host.name.toLowerCase() === "corsnet-nsw"
                  ? mrknsw
                  : cors.host.name.toLowerCase() === "ga" &&
                    (cors.status.toLowerCase() === "existing" ||
                      cors.status.toLowerCase() === "toberelocated")
                  ? mrkga
                  : cors.status.toLowerCase() === "constructing"
                  ? mrkconstruct
                  : cors.status.toLowerCase() === "poi"
                  ? mrkproposed
                  : cors.status.toLowerCase() === "planning"
                  ? mrkplanning
                  : cors.status.toLowerCase() === "decomissioned"
                  ? mrkdecomissioned
                  : mrkexisting
              }
              zIndexOffset={cors.status.toLowerCase() !== "decomissioned" && 1}>
              <Tooltip>
                <div>
                  {cors.name} - {cors.code}
                </div>
              </Tooltip>
              <Popup>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Name: </b>
                      </td>
                      <td>{cors.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Code: </b>
                      </td>
                      <td>{cors.code}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Status: </b>
                      </td>
                      <td>{cors.status}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Receiver:</b>
                      </td>

                      <td>
                        {cors.gnss_receivers.map((r) => (
                          <div>
                            <ChipIcon style={{ height: "15px" }} />
                            {r.name} <br />
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Antenna: </b>
                      </td>
                      <td>
                        {cors.antenna ? (
                          <StatusOnlineIcon style={{ height: "15px" }} />
                        ) : (
                          ""
                        )}
                        {cors.antenna}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>LAT: </b>
                      </td>
                      <td>{cors.lat}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>LON: </b>
                      </td>
                      <td>{cors.lon}</td>
                    </tr>
                    <tr style={{ verticalAlign: "top" }}>
                      <td>
                        <b>Comms:</b>
                      </td>
                      <td>
                        {cors.comms.map((c) => (
                          <div key={cors.comms.id}>
                            {c.name.toLowerCase().includes("sim") ? (
                              <ChartBarIcon style={{ height: "15px" }} />
                            ) : c.name.toLowerCase().includes("vsat") ? (
                              <LightningBoltIcon style={{ height: "15px" }} />
                            ) : (
                              <GlobeAltIcon style={{ height: "15px" }} />
                            )}
                            {c.name}
                          </div>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Host: </b>
                      </td>
                      <td>{cors.host ? cors.host.name : ""}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <PencilAltIcon style={{ height: "15px" }} />
                          Notes:{" "}
                        </b>
                      </td>
                      <td>{cors.notes}</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    display:
                      cors.status.toLowerCase() !== "existing" &&
                      cors.status.toLowerCase() !== "decomissioned" &&
                      "none",
                  }}>
                  <br />
                  {cors.status.toLowerCase() !== "planning" &&
                    cors.status.toLowerCase() !== "constructing" &&
                    cors.status.toLowerCase() !== "poi" && (
                      <FetchRxInfo site_code={cors.code} />
                    )}

                  <br />
                  <div
                    style={{
                      float: "clear",
                      alignItems: "left",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <Link to={`/gaGraph/${cors.code}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "250px" }}>
                        Coordinate Analysis
                      </Button>
                    </Link>
                  </div>
                  <div
                    style={{
                      float: "clear",
                      alignItems: "left",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "10px",
                      display:
                        cors.status.toLowerCase() === "decomissioned"
                          ? "none"
                          : "flex",
                    }}>
                    <Link to={`/status/azuremel/${cors.code}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "250px" }}>
                        Site Details
                      </Button>
                    </Link>
                  </div>
                  <br />
                  <div
                    style={{
                      float: "clear",
                      alignItems: "left",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <Link
                      to={{
                        pathname: `https://gnss-site-manager.geodesy.ga.gov.au/site/${cors.code}`,
                      }}
                      target="_blank">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "250px" }}>
                        <ExternalLinkIcon style={{ height: "15px" }} /> {"  "}
                        Site Manager
                      </Button>
                    </Link>
                  </div>
                  <br />
                  <div
                    style={{
                      float: "clear",
                      alignItems: "left",
                      width: "100%",
                      justifyContent: "center",
                      display:
                        cors.status.toLowerCase() === "constructing" ||
                        cors.status.toLowerCase() === "poi" ||
                        cors.host.name.toLowerCase() === "corsnet-nsw" ||
                        cors.status.toLowerCase() === "decomissioned"
                          ? "none"
                          : "flex",
                    }}>
                    <FetchReg13URL site_code={cors.code} />
                  </div>
                  {user.username && (
                    <>
                      <br />
                      <div
                        style={{
                          float: "clear",
                          alignItems: "left",
                          width: "100%",
                          justifyContent: "center",
                          display:
                            cors.status.toLowerCase() === "constructing" ||
                            cors.status.toLowerCase() === "poi" ||
                            cors.host.name.toLowerCase() === "corsnet-nsw"
                              ? "none"
                              : "flex",
                        }}>
                        <Link>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              if (user.username) {
                                handleCorsAssetDialogOpen(cors.name);
                                fetchAsset(cors.code);
                              }
                            }}
                            style={{
                              width: "250px",
                              display:
                                cors.status.toLowerCase() === "decomissioned" &&
                                "none",
                            }}>
                            Site Assets
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Popup>
            </Marker>
          ))}
        </LayersControl>
      </Map>

      <Dialog
        classes={{ paper: classes.paper }}
        open={assetDialogOpen}
        fullWidth
        onClose={handleCorsAssetDialogClose}>
        <DialogTitle style={{ width: "100%" }} id="draggable-dialog-title">
          {selectCors} Site Assets{" "}
          <Badge variant="primary">{assets.length} </Badge>
          <br />
          {circuitId === "" ? (
            ""
          ) : circuitId === null ? (
            ""
          ) : (
            <Chip label={`Circuit ID: ${circuitId}`} color="primary" />
          )}
        </DialogTitle>
        <DialogContent>
          <div>
            {assetLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table" className={classes.table}>
                  <TableHead>
                    <TableCell className={classes.TableCell}>Tag</TableCell>
                    <TableCell className={classes.TableCell}>Name</TableCell>
                    <TableCell className={classes.TableCell}>Serial</TableCell>
                    <TableCell className={classes.TableCell} colSpan="2">
                      Link
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {assets.map((a) => (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          {a.asset_tag}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {a.name}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {a.serial}
                        </TableCell>

                        <TableCell className={classes.TableCell}>
                          <Button
                            onClick={() =>
                              window.open(
                                `https://asset.vicpos.com.au/hardware/${a.id}`
                              )
                            }
                            target="_blank"
                            variant="contained"
                            color="primary">
                            {" "}
                            <ClipboardIcon style={{ height: "20px" }} />
                          </Button>
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          <Button
                            onClick={() =>
                              window.open(
                                `https://asset.vicpos.com.au/hardware/${a.id}#maintenances`
                              )
                            }
                            target="_blank"
                            variant="contained"
                            color="primary">
                            {" "}
                            <ClockIcon style={{ height: "20px" }} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {assets.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              disabled={assets[0].rtd_location === null}
              onClick={() => {
                if (assets[0].rtd_location !== null) {
                  window.open(
                    `https://asset.vicpos.com.au/locations/${assets[0].rtd_location.id}`
                  );
                }
              }}>
              Location
            </Button>
          ) : (
            <></>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCorsAssetDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{ paper: classes.paper }}
        open={corsDialogOpen}
        fullWidth
        onClose={handleCorsDialogClose}>
        <DialogContent>
          {remainCors.map((cors) => (
            <Chip
              color="primary"
              onDelete={() => {
                filteredCors.push(cors);
                setRemainCors(remainCors.filter((rc) => rc.id !== cors.id));
              }}
              onClick={() => {
                if (user.username) {
                  handleCorsAssetDialogOpen(cors.name);
                  fetchAsset(cors.code);
                }
              }}
              style={{
                margin: "5px",
                height: "30px",
                backgroundColor:
                  cors.status.toLowerCase() === "poi"
                    ? "#FF6373"
                    : cors.host.name.toLowerCase() === "ga"
                    ? "#FFBE57"
                    : cors.status.toLowerCase() === "constructing"
                    ? "#2596be"
                    : "#3DB651",
              }}
              label={cors.name}
              avatar={
                <Avatar
                  variant="rounded"
                  style={{
                    height: "20px",
                    width: "50px",
                    backgroundColor: "#1a6985",
                  }}>
                  {cors.code}
                </Avatar>
              }
              lable={cors.name}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({ type: "replaceCors", payload: remainCors });
              setRemainCors([]);
            }}>
            Pin to map
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRemainCors([])}>
            Clear
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCorsDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CorsMap;
