// below detail are used to allow user to download the s3 bucket file without public access.

const awsConfig = {
  accessKeyId: "AKIARJESMFTBYOXJUKRG",
  secretAccessKey: "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",
  region: "ap-southeast-2",
  bucketName: "gpsnet-luv-gnss-data",
};

export default awsConfig;
