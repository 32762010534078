import React from "react";

export const MapLegends = () => {
  return (
    <div>
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-green.png"}
        alt="green marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"GPSnet "}
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-red.png"}
        alt="red marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"Point of Interest "}
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-yellow.png"}
        alt="yellow marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"NPI "}
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-blue.png"}
        alt="blue marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"CORSNet-NSW "}
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-purple.png"}
        alt="purple marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"Constructing "}
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-dark.png"}
        alt="grey marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"Planning "}
      <img
        src={process.env.PUBLIC_URL + "map-location-icon-grey.png"}
        alt="dark marker"
        style={{ width: "17px", height: "25px" }}></img>
      {"Decomissioned "}
    </div>
  );
};
